import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  PageHeaderCustomV2WhiteWide,
  PageHeaderStandard,
} from "../../components/page-header/page-header";
import { useCurrentPath } from "../../hooks/current_path";
import { graphql, StaticQuery } from "gatsby";
import {
  TypeAboutUsPageQueryResult,
  getPage,
  TypeAboutUsPerson,
  TypeAboutUsBenefit,
} from "../../utils/queries/about_us_page";
import { SectionDivider } from "../../components/section-divider/section-divider";
import animateScrollTo from "animated-scroll-to";
import Linked from "../../utils/linkedItem";
import { getScrollToElement } from "../../utils/getScrollToElement";
import { PageTitle } from "../../components/title/title";
import AmazeeIoMetadata from "../../components/metadata";
import linkResolver from "../../utils/linkResolver";
import AmazeePage from "../../components/layout";
import { ResourceInteruptionAboutUsStatic } from "../../templates/resources/customers";
import "./about-us.scss";

import RichTextRender from "../../utils/rich-text";
import usePreviewData from "../../utils/usePreviewData";
import { Helmet } from "react-helmet";

class InitialLoadScrollTo extends React.Component {
  state: any = {
    fired: false,
  };

  constructor(params: any) {
    super(params);

    this.state = {
      fired: false,
    };

    this.run = this.run.bind(this);
  }

  run() {
    const el = getScrollToElement();
    if (el) {
      animateScrollTo(el);
    }
  }

  componentDidUpdate() {
    this.run();
  }

  componentDidMount() {
    this.run();
  }

  render() {
    return <div />;
  }
}

const ArrowRight = () => {
  return (
    <svg
      className="arrow-right"
      preserveAspectRatio="xMidYMid meet"
      data-bbox="6.443 47.497 187.114 105.008"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="6.443 47.497 187.114 105.008"
      role="img"
    >
      <g>
        <path d="M174.476 51.261l-74.704 74.193L25.578 50.75c-4.287-4.316-11.566-4.341-15.882-.054s-4.341 11.565-.055 15.882l82.107 82.673c4.287 4.316 11.566 4.341 15.882.055l82.673-82.107c4.316-4.287 4.341-11.566.055-15.882s-11.235-4.342-15.882-.056z"></path>
      </g>
    </svg>
  );
};

const AboutUsPerson = ({ item }: { item: TypeAboutUsPerson }) => {
  if (!item) {
    return null;
  }
  const backgroundImage = item.document.data.headshot
    ? item.document.data.headshot.url
    : "";
  const firstName = item.document.data.name.split(" ")[0];
  const lastName = item.document.data.name
    .split(" ")
    .slice(1)
    .join(" ");

  return (
    <div className="col-6 col-md-3 about-us-person-wrapper">
      <a
        className="about-us-person"
        style={{ backgroundImage: `url('${backgroundImage}')` }}
        href={`/about-us/people/${item.document.data.urlslug}/`}
      >
        <div className="layover">
          <h6>
            {firstName} {lastName}
          </h6>
          <div>{RichTextRender(item.document.data.role)}</div>
          <ArrowRight />
        </div>
      </a>
      <a
        href={`/about-us/people/${item.document.data.urlslug}`}
        className="about-us-person-data d-block d-md-none"
      >
        <h6>
          {firstName} {lastName}
        </h6>
        <SectionDivider />
        <div>{RichTextRender(item.document.data.role)}</div>
      </a>
    </div>
  );
};

export const AboutUsBenefit = ({ item }: { item: TypeAboutUsBenefit }) => {
  return (
    <div className="col-12 col-md-6 about-us-benefit">
      {item.icon ? (
        <div className="img">
          <img src={item.icon.url} alt={item.icon.alt} />
        </div>
      ) : null}
      <h3 className="title">{item.name}</h3>
      <div className="description">{RichTextRender(item.description)}</div>
    </div>
  );
};

const AboutUsPage = ({
  data,
  location,
}: {
  location: Location;
  data: TypeAboutUsPageQueryResult;
}) => {
  const edge = data.allPrismicAboutUsPage.edges.slice(0, 1).pop();
  if (!edge) return null;
  const result = usePreviewData(edge.node.data, "prismicAboutUsPage", true);
  const title = result.meta_title ? result.meta_title : result.title;

  console.log("location:", location);

  return (
    <AmazeePage location={location}>
      <div id="about-us">
        <PageTitle title={"Learn About Us | amazee.io"} />
        <PageHeaderStandard
          component={PageHeaderCustomV2WhiteWide}
          header={result.title}
          image={result.hero_image}
          subheader={result.subtitle}
          withSiteName={false}
          hasSectionDivider={false}
        />

        <AmazeeIoMetadata
          title={"Learn About Us | amazee.io"}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <section id="we-help">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-10 text-center">
                <h3>{RichTextRender(result.section_title1)}</h3>
              </div>
              <div className="col-12 col-lg-8 text-center">
                <h6>{RichTextRender(result.section_subtitle1)}</h6>
              </div>
            </div>

            <div className="row justify-center">
              {result.services.map((service, index) => (
                <div className="col-12 col-lg-5 service" key={index}>
                  <img src={service.image.url} alt={service.image.alt} />
                  <div className="service-title">{service.title1}</div>
                  <div className="description">{service.description}</div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="globally-remote">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-10">
                <div className="row d-flex align-items-center">
                  <div className="col-12 col-lg-7">
                    <div className="content-wrapper">
                      <h4 className="headline avenir">{result.headline}</h4>
                      <div className="content">
                        {RichTextRender(result.section_1_content)}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-5">
                    <img src={result.image.url} alt={result.image.alt} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="scrollto-anchor" id="people" />
        <section className="people">
          <div className="container">
            <div className="row text-center">
              <div className="col-12">
                <h4>Meet our amazeeng team!</h4>
              </div>
            </div>
            <div className="row">
              {result.person.map((item, index) => (
                <AboutUsPerson key={index} item={item.personlink} />
              ))}
            </div>
          </div>
        </section>

        <section className="benefits">
          <div className="container">
            <div className="row justify-center">
              <div className="col-12 text-center">
                <h4 className="section-title">{result.title3}</h4>
              </div>

              <div className="col-12 col-md-10 col-lg-10">
                <div className="row align-items-start">
                  {result.benefit.map((item, index) => (
                    <AboutUsBenefit item={item} key={index} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <ResourceInteruptionAboutUsStatic topMargin={false} />

        <InitialLoadScrollTo />
      </div>
    </AmazeePage>
  );
};

export default ({ location }: { location: Location }) => {
  const query = graphql`
    {
      allPrismicAboutUsPage(limit: 1) {
        edges {
          node {
            data {
              headline
              hero_image {
                alt
                url
              }
              image {
                alt
                copyright
                url
                thumbnails
              }
              section_1_content {
                html
                text
                raw
              }
              services {
                title1
                description
                image {
                  alt
                  url
                }
              }
              section_title1
              section_subtitle1
              subtitle
              title
              meta_title
              title3
              page_meta_description
              page_meta_thumbnail {
                alt
                copyright
                url
                thumbnails
              }
              benefit {
                description {
                  html
                  text
                  raw
                }
                icon {
                  alt
                  copyright
                  url
                  thumbnails
                }
                name
              }
              person {
                personlink {
                  document {
                    ... on PrismicAboutuspersonpage {
                      id
                      data {
                        role {
                          html
                          raw
                          text
                        }
                        name
                        linkedin_url
                        headshot {
                          alt
                          url
                        }
                        github_url
                        description {
                          html
                          raw
                          text
                        }
                        urlslug
                        twitter_url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
  return (
    <StaticQuery
      query={query}
      render={(data) => <AboutUsPage location={location} data={data} />}
    />
  );
};
